import {
    getAllSitePages,
    getCdnLibrary,
    getCustomCss,
    getCustomScript,
    getDefaultFooters,
    getDefaultHeaders,
    getDefaultSitePages,
    getLanguages,
    getSidebars,
    searchFooters,
    searchHeaders,
    searchUrl
} from "../../service";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import Keys from "../../_config";
import LoadScript from 'react-load-script';

const parse = require('html-react-parser');

export default function WebsiteClientV4() {

    const [customCss, setCustomCss] = useState("")
    const [headerScript, setHeaderScript] = useState([])
    const [headerLink, setHeaderLink] = useState([])
    const [headerHtml, setHeaderHtml] = useState("")

    const [cdnScript, setCdnScript] = useState([])
    const [cdnLink, setCdnLink] = useState([])

    const [allSideBar, setAllSidebar] = useState([])
    const [allPages, setAllPages] = useState([]);
    const [defaultPage, setDefaultPage] = useState([]);
    const [displayPage, setDisplayPage] = useState("");
    const [displaySidebar, setDisplaySidebar] = useState("");
    const [displayPageId, setDisplayPageId] = useState("");

    const [footerHtml, setFooterHtml] = useState("")

    const [loading, setLoading] = useState(true);
    const [customScriptRun, setCustomScriptRun] = useState(false);
    const [pageScriptRun, setPageScriptRun] = useState(true);
    const [sidebarScriptRun, setSidebarScriptRun] = useState(true);

    const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    const location = useLocation();
    const navigate = useNavigate();

    const handleScriptLoad = (javascriptArray, run = false) => {
        try {
            // The script has loaded, now you can execute the JavaScript code
            console.log(javascriptArray)
            if (run) {
                javascriptArray?.length > 0 && javascriptArray?.forEach((jsString) => {
                    setTimeout(() => {
                        const scriptFunction = new Function(jsString);
                        scriptFunction();
                    }, 500)
                });
            }
            // setScriptsLoaded(true);
        } catch (error) {
            console.error('Error executing JavaScript:', error);
        }
    };


    const getCustomCssFunction = async () => {
        try {
            const response = await getCustomCss();

            if (response?.status === 200 && response?.list?.length > 0) {
                const customCssItem = response.list[0];
                const css = customCssItem.minifiedCss || customCssItem.details;

                if (css) {
                    setCustomCss(css);
                }
            }

        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const getHeaderFunction = async () => {
        try {
            const pathSegments = location.pathname.split('/');
            const pathName = pathSegments[1];
            const {script, link, details} = pathName === 'header'
                ? await searchUrl({path: location.pathname})
                    .then(res => res.type === 'Header' ? searchHeaders({documentID: res.referralID}) : null)
                : await getDefaultHeaders();

            if (script && link) {
                setHeaderScript(script);
                setHeaderLink(link);
            }

            if (details && details?.length > 0) {
                const header = details?.find(item => item?.language === lang)?.details;
                await setHeaderHtml(header);
            }

        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const getFooterFunction = async () => {
        try {

            const pathSegments = location.pathname.split('/');
            const pathName = pathSegments[1];
            const {details} = pathName === 'footer'
                ? await searchUrl({path: location.pathname})
                    .then(res => res.type === 'Footer' ? searchFooters({documentID: res.referralID}) : null)
                : await getDefaultFooters();

            if (details && details?.length > 0) {
                const footer = details?.find(item => item?.language === lang)?.details;
                await setFooterHtml(footer)
            }

        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const getCdnListFunction = async () => {
        try {
            const response = await getCdnLibrary();

            const script = response
                .filter(item => item.type === "script")
                .map(item => ({
                    src: Keys.SERVER_URL + item.filePath
                }));

            const css = response
                .filter(item => item.type === "css")
                .map(item => ({
                    href: Keys.SERVER_URL + item.filePath,
                    rel: "stylesheet"
                }));

            await Promise.all([
                setCdnScript(script),
                setCdnLink(css)
            ]);

        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const getLanguageFunction = async () => {
        try {
            const res = await getLanguages();
            const selectedLanguageCode = localStorage.getItem("lang");

            const languageExists = res?.list?.some(item => item?.code === selectedLanguageCode);

            if (!languageExists) {
                localStorage.setItem("lang", "en");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const getAllSitePagesFunction = async () => {
        const response = await getAllSitePages()
        const defaultPage = await getDefaultSitePages()
        setAllPages(response)
        setDefaultPage(defaultPage)
    }

    const getAllSideBarFunction = async () => {
        const response = await getSidebars()
        setAllSidebar(response)
    }

    const getDefaultScript = async () => {
        await setCustomScriptRun(false)
        const response = await getCustomScript()
        // if (response.list) {
        //
        //     response.list.length > 0 && response.list[0].scripts.map((value, i) => {
        //         const elementID = document.getElementById("defaultScript-" + i)
        //         if (!elementID) {
        //             let result = value.replace("const ", "var ");
        //             const scriptText = document.createTextNode(`${result}`);
        //
        //             const script = document.createElement("script");
        //             script.setAttribute("id", "defaultScript-" + i)
        //             script.setAttribute("class", "defaultScript")
        //
        //             script.appendChild(scriptText);
        //             setTimeout(() => {
        //                 document.body.appendChild(script);
        //             }, 1000)
        //         }
        //     })
        //
        // }
        if (response?.list && response?.list[0]?.scripts?.length > 0 && !customScriptRun) {
            await setCustomScriptRun(true)
            handleScriptLoad(response.list[0].scripts, true)
        }
    };

    const assignPage = async () => {
        await setPageScriptRun(true)
        await setSidebarScriptRun(true)

        if (location?.pathname === "/") {
            const detail = defaultPage?.details?.find((html) => html?.language === lang)
            setDisplayPage(detail?.details)
            setDisplayPageId(detail?.pageID)

            if (defaultPage?.sidebarID && defaultPage?.sidebarID.length > 5) {

                const foundSidebar = allSideBar?.find((item) => item?._id === defaultPage?.sidebarID)

                if (foundSidebar) {
                    const sidebar = foundSidebar?.details?.find((item) => item?.language === lang)
                    setDisplaySidebar(sidebar?.details)

                    if (foundSidebar?.scripts?.length > 0 && sidebarScriptRun) {
                        handleScriptLoad(foundSidebar?.scripts, sidebarScriptRun)
                        setSidebarScriptRun(false)
                    }
                }
            } else {
                setDisplaySidebar("")
            }

            if (pageScriptRun) {
                handleScriptLoad(defaultPage?.scripts, pageScriptRun)
                setPageScriptRun(false)
            }

        } else {

            const pathSegments = location.pathname.split('/');
            const firstPathSegment = pathSegments[1];
            let pageFound = {}
            if (firstPathSegment === "sites" || firstPathSegment === "post") {
                pageFound = allPages?.find((html) => html?.defaultPath === location?.pathname)
            } else {
                pageFound = allPages?.find((html) => html?.dynamicPath === location?.pathname)
            }

            const detail = pageFound?.details?.find((html) => html?.language === lang)
            await setDisplayPage(detail?.details)
            await setDisplayPageId(pageFound?.pageID)

            if (pageFound?.sidebarID && pageFound?.sidebarID.length > 5) {

                const foundSidebar = allSideBar?.find((item) => item?._id === pageFound?.sidebarID)

                if (foundSidebar) {
                    const sidebar = foundSidebar?.details?.find((item) => item?.language === lang)
                    setDisplaySidebar(sidebar?.details)

                    if (foundSidebar?.scripts?.length > 0 && sidebarScriptRun) {
                        handleScriptLoad(foundSidebar?.scripts, sidebarScriptRun)
                        setSidebarScriptRun(false)
                    }

                }
            } else {
                setDisplaySidebar("")
            }
            if (pageScriptRun) {
                handleScriptLoad(pageFound?.scripts, pageScriptRun)
                setPageScriptRun(false)
            }
        }
        window.scrollTo(0, 0);
    }

    const preload = async () => {
        await getAllSitePagesFunction()
        await getAllSideBarFunction()
        await getCustomCssFunction()
        await getLanguageFunction()
        await getCdnListFunction()
        await getHeaderFunction()
        await getFooterFunction()
        setLoading(false)
    }

    useEffect(() => {
        preload()
    }, [])

    useEffect(() => {

        if (!loading) {
            const process = async () => {
                await assignPage()
                await getDefaultScript()
            }
            process()
        }

    }, [location, loading, lang])

    //languages
    useEffect(() => {
        const languageChange = async () => {
            const queryParams = new URLSearchParams(location.search);
            const paramValue = queryParams.get('lang');

            if (paramValue !== lang) {
                const res = await getLanguages();
                const langFound = res?.list.find((data) => data?.code === paramValue)
                if (langFound) {
                    localStorage.setItem("lang", paramValue)
                    window.location.reload();
                }
            }
        }
        languageChange()
    }, [location])

    return (
        <div>

            <Helmet script={cdnScript} link={cdnLink}/>

            <Helmet
                script={headerScript}
                link={headerLink}
            />
            {
                customCss && (
                    <style>
                        {customCss}
                    </style>
                )
            }

            {
                loading ? (
                    <div id="loading-screen" style={{background: Keys.background, overflow: "hidden"}}>
                        <img className={Keys.LOGO_CLASSNAME + " logo"} src={Keys.LOGO}/>
                    </div>
                ) : null
            }

            <div
                style={loading ? {visibility: "hidden", overflow: "hidden"} : {visibility: "unset", overflow: "unset"}}>
                {headerHtml && parse(headerHtml)}

                {
                    displaySidebar && displaySidebar !== "" ? (
                        <div id={displayPageId} className={"container"}>
                            <div className="row">
                                <div className="col-md-8">
                                    {displayPage && parse(displayPage)}
                                </div>
                                <div className="col-md-4">
                                    {displaySidebar && parse(displaySidebar)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div id={displayPageId}>{displayPage && parse(displayPage)}</div>
                    )
                }


                {footerHtml && parse(footerHtml)}
            </div>

            <LoadScript url={null} onLoad={handleScriptLoad}/>

        </div>
    )

}





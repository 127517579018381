import React, {useEffect, useState} from "react";
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import './assets/style/main.css';
import DefaultLayout from './layout/index.js';
import Index from "./route";
import {useLocation, useNavigate} from 'react-router-dom';
import {getBrandSetting, getUrl, logoutUser, verifyToken} from "./service";
import {Helmet} from "react-helmet";
import Keys from "./_config";
import {message} from "antd";

function App() {

    const location = useLocation();
    const navigate = useNavigate();
    const [urlPath, setUrlPath] = useState(false);
    const [brandSetting, setBrandSetting] = useState(false);

    const dispatchURL = () => {
        getUrl().then(async (response) => {
            let path = [];
            response.map((res) => {
                path.push(res.path)
            })
            setUrlPath(path)
        });
    };

    const dispatchBrandSetting = () => {
        getBrandSetting().then(async (response) => {
            if (response.list.length > 0) {
                setBrandSetting(response.list[0])
            }
        });
    };

    const sessionExpired = async () => {
        // await logoutUser()
        localStorage.removeItem("userID")
        localStorage.removeItem("a_t")
        message.error('Session Expired');
        navigate("/admin/login")
    }

    const trackUserActivity = (timeoutInMinutes, logoutFunction) => {
        let timer;
        let isLoggedIn = true;
        let isPageVisible = true;
        const broadcastChannel = new BroadcastChannel("userActivityChannel"); // Create a new Broadcast Channel

        const expiredFunction = () => {
            if (isLoggedIn && isPageVisible) {
                isLoggedIn = false; // Set the flag to false to indicate user logout
                clearTimeout(timer);
                logoutFunction();
            }
        };

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(expiredFunction, timeoutInMinutes);
        };

        const clearTimer = () => {
            clearTimeout(timer);
        };

        const activityHandler = () => {
            resetTimer();
            broadcastChannel.postMessage("userActive"); // Notify other tabs that user is active
        };

        // Add event listeners for user activity
        window.addEventListener("mousemove", activityHandler);
        window.addEventListener("keydown", activityHandler);
        window.addEventListener("mousedown", activityHandler);

        // Listen for broadcast messages from other tabs
        broadcastChannel.onmessage = (event) => {
            if (event.data === "userActive") {
                resetTimer(); // Reset the timer when a broadcast message is received from another tab indicating user activity
            }
        };

        // Initialize the timer
        resetTimer();

        // Function to stop tracking user activity and clear the timer
        const stopTrackingUserActivity = () => {
            isLoggedIn = false;
            window.removeEventListener("mousemove", activityHandler);
            window.removeEventListener("keydown", activityHandler);
            window.removeEventListener("mousedown", activityHandler);
            broadcastChannel.close(); // Close the Broadcast Channel when stopping tracking
            clearTimer();
        };

        // Return the function to stop tracking user activity
        return stopTrackingUserActivity;
    };

    const checkToken = async () => {
        if (location?.pathname?.split('/')[1] === "admin" && localStorage.getItem("a_t")) {
            const verification = await verifyToken({token: localStorage.getItem("a_t") || null})

            if (verification?.status === 500) {
                await sessionExpired()
            }
        }
    }

    useEffect(() => {
        dispatchURL();
        dispatchBrandSetting();
    }, []);

    useEffect(() => {
        if (location?.pathname?.split('/')[1] === "admin" && localStorage.getItem("a_t") && localStorage.getItem("userID")) {
            trackUserActivity(10 * 60 * 1000,sessionExpired)
        }
    }, []);

    useEffect(() => {
        checkToken()
    }, [location]);

    // Router settings
    if (location.pathname === "/admin/login" || location.pathname === "/admin/register" || location.pathname.split('/')[1] === "api" || urlPath && urlPath.includes(location.pathname) || location.pathname === "/") {
        return (
            <div className="App">
                <Helmet
                    link={[
                        {
                            "rel": "icon",
                            "href": Keys.SERVER_URL + brandSetting.faviconPath
                        }
                    ]}/>
                <Index/>
            </div>
        );
    } else if (location.pathname.split('/')[1] === "admin") {
        return (
            <DefaultLayout>
                <div className="App">
                    <Helmet
                        title={brandSetting.title}
                        link={[
                            {
                                "rel": "icon",
                                "href": Keys.SERVER_URL + brandSetting.faviconPath
                            }
                        ]}/>
                    <Index/>
                </div>
            </DefaultLayout>
        );

    }

    // End of Router settings
}

export default App;
